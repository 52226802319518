<template lang="pug">
  v-container
    customer-application-table(@show-application="showApplication")
    v-dialog(
      max-width="30vw"
      eager
      v-model="customerApplicationShowDialog"
    )
      v-card
        customer-application-show(
          ref="customer-application-show"
        )
</template>
<script>
export default {
  name: 'CustomerApplication',
  components: {
    customerApplicationTable: () => import('./Table'),
    customerApplicationShow: () => import('./Show'),
  },
  data () {
    return {
      customerApplicationShowDialog: false,
    }
  },
  methods: {
    showApplication (data) {
      const { populateFields } = this.$refs['customer-application-show']
      populateFields(data)
      this.customerApplicationShowDialog = true
    },
  },
}
</script>